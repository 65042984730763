body{
    background-position: bottom;
    background-size: 100% auto;
    background-size: cover;
    background-image: url("../../assets/backgroundGreen2.png");
}

.dropdown-menu{
    --bs-dropdown-link-active-bg: rgba(54, 194, 41, 1);
}

*{
    font-family: 'Merriweather Sans', sans-serif;}